import React from "react";
import ChannelsConnectionModal from "components/modals/channelsConnectionModal";
import Button from "components/common/Button";

const PermissionsAddedModal: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
  onContinue: () => void;
}> = ({ isOpen, closeModal, onContinue }) => {
  return isOpen ? (
    <ChannelsConnectionModal
      headerIcon={<i className="icon-error" style={{ fontSize: "18px" }} />}
      headerTitle="Permission Required"
      description="Syncing the channel will not work without having added the necessary permissions for this channel. Please ensure that you’ve granted all required permissions."
      onClose={closeModal}
      cancelButtonLabel="Close"
    >
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          variant="primary"
          onClick={onContinue}
          style={{ marginLeft: "10px" }}
        >
          Continue
        </Button>
      </div>
    </ChannelsConnectionModal>
  ) : null;
};

export default PermissionsAddedModal;
