import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Page,
  PageName,
  PageHeader,
  ConnectionList,
  ConnectionListWrapper
} from "components/channelConnect/styles";
import Button from "components/common/Button";
import * as localStorage from "utils/storage";
import { connectChannel } from "utils/channels";
import * as sessionStorage from "utils/sessionStorage";
import { closeModal } from "state/actions/ModalActions";
import { channelConnectionHelpLinks } from "constants/index";
import { ChannelAvatar, Avatar, Service } from "components/common/styles";
import DefaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import ChannelsConnectionModal from "components/modals/channelsConnectionModal";

interface IChannelRow {
  channel: any;
  handleChannelConnect: VoidFunction;
}

const ChannelRow: React.FC<IChannelRow> = ({
  channel,
  handleChannelConnect
}) => {
  return (
    <Page>
      <PageHeader>
        <ChannelAvatar
          key={channel.id}
          mr={0}
          $enabled={true}
          $clickable={false}
        >
          <Avatar
            src={channel.avatar ? channel.avatar : DefaultAvatar}
            $isRounded={true}
            width={40}
            height={40}
            $noShadow
            onError={e => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = DefaultAvatar;
            }}
          />
          <Service
            $size={16}
            title={channel.username}
            $type={channel.service}
          />
        </ChannelAvatar>
        <PageName>
          <label htmlFor={`checkbox-${channel.id}`}>{channel.username}</label>
        </PageName>
      </PageHeader>
      <Button
        size="xl"
        isRounded={false}
        variant="primary"
        onClick={handleChannelConnect}
      >
        Refresh
      </Button>
    </Page>
  );
};

const ChannelReconnectionModal: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const account = useSelector<any, any>(state => state.account.data);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const channels = useMemo(
    () => [
      ...account.channels.filter(channel => {
        const now = +new Date();
        const period = 6.048e8;

        const storageId = `reconnect_channel_${channel.id}`;
        const lastReconnectionPrompt =
          channel.service === "linkedin" &&
          !(channel.linkedinVersion ?? "v2").includes("v2")
            ? parseInt(sessionStorage.get(storageId))
            : parseInt(localStorage.get(storageId));

        return (
          channel.connectionStatus === "needsReconnection" ||
          (channel.service === "linkedin" &&
            !(channel.linkedinVersion ?? "v2").includes("v2") &&
            (!lastReconnectionPrompt || now - lastReconnectionPrompt > period))
        );
      })
    ],
    []
  );

  const handleChannelConnect = useCallback(service => {
    connectChannel(account, service);
  }, []);

  const handleCloseModal = useCallback(() => {
    const now = +new Date();

    for (let channel of channels) {
      if (
        channel.service === "linkedin" &&
        !(channel.linkedinVersion ?? "").includes("v2")
      ) {
        sessionStorage.set(`reconnect_channel_${channel.id}`, now);
      } else {
        localStorage.set(`reconnect_channel_${channel.id}`, now);
      }
    }

    dispatch(closeModal());
  }, []);

  return (
    <ChannelsConnectionModal
      headerIcon={<i className="icon-error" style={{ fontSize: "18px" }} />}
      headerTitle="Please Refresh These Channels"
      description="The channels below need to be refreshed. This is normal practice to keep your account secure."
      onClose={handleCloseModal}
      cancelButtonLabel="I’ll do this later"
      helpLinks={_.values(
        _.pick(channelConnectionHelpLinks, ["whyNeedRefresh"])
      )}
    >
      <ConnectionListWrapper>
        <ConnectionList>
          {channels
            .sort(c =>
              c.service === "linkedin" &&
              !(c.linkedinVersion ?? "").includes("v2")
                ? -1
                : 1
            )
            .map(channel => (
              <ChannelRow
                key={channel.id}
                channel={channel}
                handleChannelConnect={() =>
                  handleChannelConnect(
                    `${channel.service}_${channel.serviceType}`
                  )
                }
              />
            ))}
        </ConnectionList>
      </ConnectionListWrapper>
    </ChannelsConnectionModal>
  );
};

export default ChannelReconnectionModal;
