import styled from "styled-components";
import React, { useState } from "react";

import iconDone from "assets/images/done.svg";
import { useComposerActions } from "contextApi/composerContext";
import { AlertTriangle } from "@styled-icons/evaicons-solid/AlertTriangle";
import { ChannelWrapper, Avatar, ChannelIcon, ChannelUsername } from "./styles";
import { ChannelConnectionStatus } from "@hellocontento/contento-common";

interface IChannel {
  checked: boolean;
  channel: {
    id: string;
    service: string;
    avatar: string;
    username: string;
    connectionStatus: ChannelConnectionStatus;
  };
  handleChannelToggle: (channelId: string) => void;
}

const ChannelSelectIcon = styled(AlertTriangle)`
  margin-left: 8px;
  cursor: pointer;
`;

const Channel: React.FC<IChannel> = ({
  checked,
  channel,
  handleChannelToggle
}) => {
  const setIsComposerCloseConfirmationOpen = useComposerActions(
    actions => actions.setIsComposerCloseConfirmationOpen
  );
  const { id, service, avatar, username, connectionStatus } = channel;
  const [isImageBroken, setIsImageBroken] = useState(false);

  const onImageError = () => {
    setIsImageBroken(true);
  };

  const onChannelToggle = () => {
    if (connectionStatus === "connected") {
      handleChannelToggle(id);
    } else {
      setIsComposerCloseConfirmationOpen(true, true);
    }
  };

  return (
    <ChannelWrapper
      checked={checked}
      onClick={onChannelToggle}
      connectionStatus={connectionStatus}
    >
      <Avatar>
        {!!avatar && !isImageBroken && (
          <img src={avatar} alt={username} onError={onImageError} />
        )}
      </Avatar>
      <ChannelIcon channel={service} />
      <ChannelUsername checked={checked}>
        <span>{username}</span>
        {connectionStatus === "needsReconnection" ? (
          <ChannelSelectIcon size={20} color="red" />
        ) : connectionStatus === "needsVerification" ? (
          <ChannelSelectIcon size={20} color="yellow" />
        ) : (
          <img src={iconDone} alt={service} />
        )}
      </ChannelUsername>
    </ChannelWrapper>
  );
};

export default Channel;
