import React from "react";
import ChannelConnectionCard from "../ChannelConnectionCard";

import iconLinkedIn from "../../../assets/images/icon-linkedIn-circ.png";
import iconFacebook from "../../../assets/images/icon-facebook.png";
import iconFacebookSquare from "../../../assets/images/channel-logo-facebook.jpg";
import iconTwitter from "../../../assets/images/icon-twitter.png";
import iconTwitterSquare from "../../../assets/images/channel-logo-twitter.jpg";
import iconInstagram from "../../../assets/images/icon-instagram-132.png";
//import iconZapierInstagram from "../../../assets/images/icon-zapier-instagram.png";

// TODO: Sprite this
export const serviceList = {
  linkedin_business: {
    name: "LinkedIn Business",
    socialIcon: iconLinkedIn,
    squareIcon: iconLinkedIn,
    disclaimer: "Connect your company page."
  },
  linkedin_profile: {
    name: "LinkedIn Profile",
    socialIcon: iconLinkedIn,
    squareIcon: iconLinkedIn,
    disclaimer: "Connect your personal account."
  },
  facebook_page: {
    name: "Facebook",
    socialIcon: iconFacebook,
    squareIcon: iconFacebookSquare,
    disclaimer: "Connect your page."
  },
  twitter_profile: {
    name: "X",
    socialIcon: iconTwitter,
    squareIcon: iconTwitterSquare,
    disclaimer: "Connect your account or page."
  },
  linkedin_page: {
    name: "LinkedIn Page",
    socialIcon: iconLinkedIn,
    squareIcon: iconLinkedIn
  },
  instagram_profile: {
    name: "Instagram Profile",
    socialIcon: iconInstagram,
    squareIcon: iconInstagram,
    disclaimer: "Connect your personal profile."
  },
  instagram_business: {
    name: "Instagram",
    socialIcon: iconInstagram,
    squareIcon: iconInstagram,
    disclaimer: "Connect your business page."
  }
};

function AvailableService({
  disabled,
  service,
  channel,
  status,
  onConnect,
  onDisconnect,
  connected
}) {
  return (
    <ChannelConnectionCard
      disabled={disabled}
      status={status}
      connectionStatus={channel?.connectionStatus}
      onConnect={onConnect}
      onDisconnect={onDisconnect}
      socialIcon={serviceList[service].socialIcon}
      squareIcon={serviceList[service].squareIcon}
      channel={channel}
      avatar={channel ? channel.avatar : null}
      username={channel ? channel.username : serviceList[service].name}
      instruction={
        serviceList[service].disclaimer ? serviceList[service].disclaimer : null
      }
      connected={connected}
    />
  );
}

export default AvailableService;
