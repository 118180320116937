import { IChannel } from "@hellocontento/contento-common";
import { POST_STATUS } from "constants/post";
import { SERVICES } from "constants/services";

export const constructInitialPostState = (
  post: any,
  accountChannels: any[],
  initData: any = {}
) => {
  const {
    taskId,
    postIdea,
    contentId,
    attachment,
    initialContent,
    contentTypeLabel,
    initialChannelIds,
    initialContentTypeId
  } = initData;

  // Pre-process accountChannels to create a Set of connected IDs
  const connectedAccountChannelIds = new Set(
    accountChannels
      .filter(acctChannel => acctChannel.connectionStatus !== "needsReconnection")
      .map(acctChannel => acctChannel.id)
  );

  let postData: any = {};
  
  if (post && !post?.isDraft && !post?.isPostGroup) {
    const caption = {
      all: post.caption,
      [post.channel.service]: post.caption
    };
    postData = Object.assign(
      {},
      post,
      { caption },
      {
        channels: [post.channel.id],
        status: post.status ? post.status : POST_STATUS.SCHEDULED
      }
    );
  } else if (post && (post?.isDraft || post?.isPostGroup)) {
    // compose an object to accommodate draft and post group objects
    const all =
      post.caption.all ??
      Object.values(post.caption).sort((a: any, b: any) => {
        return b.length - a.length;
      })[0];

    postData = Object.assign(
      {},
      post,
      { caption: { ...post.caption, all } },
      {
        channels: post.channels
          .filter(channel => connectedAccountChannelIds.has(channel.id))
          .map(channel => channel.id),
        status: post.status ? post.status : POST_STATUS.SCHEDULED
      }
    );
  } else {
    const linkedinProfileChannelCount = accountChannels.filter(
      c => c.service === "linkedin" && c.serviceType === "profile"
    ).length;

    postData = {
      channels:
        initialChannelIds ||
        accountChannels
          .filter(channel => channel.service !== SERVICES.INSTAGRAM)
          .filter(c => {
            return !(
              linkedinProfileChannelCount > 1 &&
              c.service === SERVICES.LINKEDIN &&
              c.serviceType === "profile"
            );
          })
          .filter(c => connectedAccountChannelIds.has(c.id))
          .map(channel => channel.id),
      caption: {
        all: initialContent ? initialContent : ""
      },
      ...(attachment ? { attachment } : {}),
      status: POST_STATUS.SCHEDULED,
      contentId: contentId,
      contentTypeId: initialContentTypeId || "educational",
      contentTypeLabel: contentTypeLabel,
      postIdea: postIdea || null,
      taskId: taskId
    };
  }

  return postData;
};

export const computeInitialVisibleCaption = (
  postData: any,
  accountChannels: IChannel[]
) => {
  const isCombined =
    !!postData.caption?.all &&
    Object.values(postData.caption).every(
      value => value === postData.caption.all
    ) &&
    (!postData.mentions || postData.mentions.length < 1);

  const initialVisibleCaption = !postData.id
    ? "all"
    : (postData.isDraft || postData.isPostGroup) && isCombined
      ? "all"
      : (accountChannels as IChannel[]).find(
          channel => channel.id === postData.channels[0]
        )!.service;

  return initialVisibleCaption;
};
